.vf_ps_roundedBorder {
  -webkit-border-radius: 6px !important;
  border-radius: 6px !important;
}

.vf_ps_shadowedBox {
  -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.23) !important;
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.23) !important;
}

.vf_ps_pls {
  display: inline-block;
  margin: 0 auto;
  outline: 0 none;
  border: 0 none;
  padding: 17px 0 16px;
  width: 100%;
  font-size: 14px;
  text-align: center !important;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  vertical-align: middle;
  transition: all 456ms ease;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.vf_rzf_pls-green {
  color: #fff;
  background: #009900;
}

.vf_rzf_pls-green:hover {
  color: #fff;
  background: #008800;
}

#vf_ps_popPorting {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99999;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#vf_ps_popPorting {
  font-family: 'Vodafone Rg', 'vodafone_rgregular', sans-serif;
}

#vf_ps_popPorting * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

#vf_ps_popPorting b,
#vf_ps_popPorting strong {
  font-weight: normal;
  font-family: 'Vodafone Rg Bold', 'vodafone_rgbold', sans-serif;
}

#vf_ps_popPorting figure,
#vf_ps_popPorting picture {
  margin: 0;
  font-size: 0;
  line-height: 0;
}

.vf_ps_closePopWind {
  position: absolute !important;
  top: 30px;
  right: 20px;
  width: 20px;
  height: 20px;
  z-index: 9999;
}

.vf_ps_closePopWind::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  border-top: 2px solid #fff;
  width: 20px;
  transform: rotate(45deg);
}

.vf_ps_closePopWind::after {
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  border-top: 2px solid #fff;
  width: 20px;
  transform: rotate(-45deg);
}

.vf_ps_popPorting {
  padding: 32px 20px;
  width: 90vw;
  height: max-content;
  background: #b40000
    url(https://a-wb.vodafone.it/content/dam/webaem/img/campagne/wind-2023/bg-popup_ms.jpg)
    no-repeat 50% / cover;
}

.vf_ps_popPorting header {
  margin: 0 0 16px;
}

.vf_ps_popPorting header h2 {
  margin: 0;
  font-size: 36px;
  line-height: 41px;
  color: #fff;
}

.vf_ps_popPorting header p {
  margin: 8px 0 0;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
}

.vf_ps_popPorting aside {
  padding: 24px 32px;
  text-align: center;
  background: #fff;
}

.vf_ps_popPorting aside p {
  display: inline-block;
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}

.vf_ps_popPorting aside p strong {
  font-size: 30px;
}

.vf_ps_popPorting aside p strong:first-of-type {
  display: block;
  margin: 0 0 16px;
}

.vf_ps_popPorting aside p b {
  display: block;
}

.vf_ps_popPorting aside p del {
  text-decoration: line-through;
}

.vf_ps_popPorting aside ul {
  display: inline-block;
  margin: 16px auto 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.vf_ps_popPorting aside ul li {
  font-size: 18px;
  line-height: 24px;
}

.vf_ps_popPorting aside ul li ~ li {
  margin-top: 10px;
}

.vf_ps_popPorting aside ul li img {
  position: relative;
  float: left;
  width: 20px;
  height: auto;
  margin-right: 18px;
}

.vf_ps_popPorting footer {
  margin: 24px 0 0;
}

.vf_ps_popLead {
  display: none;
  width: 385px;
  height: 500px;
  background: #fff;
}

.vf_ps_popLead .vf_ps_closePopWind::before,
.vf_ps_popLead .vf_ps_closePopWind::after {
  border-top-color: #000;
}

.vf_ps_popLead iframe {
  position: relative;
  width: 375px;
  height: 500px;
  margin: 0;
  border: 0 none;
  padding: 0;
  z-index: 0;
}

@media (min-width: 767px) {
  .vf_ps_popPorting {
    padding: 32px 74px;
    width: 640px;
    height: max-content;
    background-image: url(https://a-wb.vodafone.it/content/dam/webaem/img/campagne/wind-2023/bg-popup_dt.jpg);
  }

  .vf_ps_popLead {
    width: 690px;
    height: 464px;
  }

  .vf_ps_popLead iframe {
    width: 656px;
    height: 464px;
  }

  .vf_ps_popPorting aside p {
    margin: 0 23px 0 0;
    border-right: 1px solid #333;
    padding: 0 23px 0 0;
    vertical-align: middle;
  }

  .vf_ps_popPorting aside ul {
    margin: 0;
    vertical-align: middle;
  }
}

